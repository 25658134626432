<template>
  <div>
    <a-row :gutter="16">
      <a-col :sm="24" :xl="12" :xxl="8" style="margin-bottom: 16px;">
        <a-range-picker :allow-clear="false" :default-value="datetimeRange" :disabled-date="disabledDate"
          :ranges="ranges" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm"
          @ok="v => { this.datetimeRange = v }"></a-range-picker>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col v-for="chartKey in chartKeys" :key="chartKey.key" :xs="24" :xl="12" :xxl="8"
        style="margin-bottom: 16px;">
        <monitor-chart-card :datetime-range="datetimeRange" source-type="web" :chartKey="chartKey">
        </monitor-chart-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { getWebMonitorHistory } from '@/api/web'
import MonitorChartCard from '@/components/chart/MonitorChartCard'
import { getDatetimeRanges } from '@/utils'

export default {
  name: 'HistoryTab',
  components: { MonitorChartCard },
  data () {
    return {
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      ranges: getDatetimeRanges(),
      chartKeys: [
        { key: 'web.test.in', title: '下载速度 (bps)' },
        { key: 'web.test.rspcode', title: '状态码' },
        { key: 'web.test.time', title: '响应时间 (ms)' }
      ],
      historyFunc: getWebMonitorHistory
    }
  },
  methods: {
    disabledDate (date) {
      return date && date > moment().endOf('day')
    }
  }
}
</script>
